html {
  font-size: 14px;
}

body {
  margin: 0;
  font-family: 'Poppins', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 100%;
  line-height: 1.4;
}

main {
  display: flex;
  flex-basis: 100%;
  flex-grow: 1;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


.ant-select-item-option-content {
  overflow: visible !important;
  white-space: normal !important;
}

.language-picker .ant-select {
  width: 6rem;
}


@media screen and (min-width: 992px) {

  .language-picker {
    display: flex;
  }

  .language-picker .ant-select {
    width: 6rem;
  }
}

@page {
  margin: 3mm;
}


@media print {
  .query-controls {
    display: none !important;
  }

  .wh-Layout-responsive-layout1breadcrumb {
    display: none !important;
  }
  .wh-Layout-responsive-layout1header {
    display: none !important;
  }
  .hcNOQa {
    display: none !important;
  }
  .wh-UI-Responsive-Sidebar-mobile-navigation-bar{
    display: none !important;
  }
  .wh-Layout-responsive-layout1heightContainer {
    max-height: none !important;
    height: 100% !important;
  }
  .PtzOC {
    max-height: none !important;
    height: 100% !important;
  }

  body {
    margin: 0;
    box-shadow: none;
    zoom: 95%
  }

}

html, body, #root { height: 100%; }

main {
  overflow: hidden;
}

.react-tel-input .form-control {
  height: 35.14px !important;
}

.react-tel-input {
  height: 35.14px !important;
}

.ant-picker-input > input, .ant-picker, .ant-select-selector, input, .react-tel-input {
  font-size: 16px !important;
}
